<template>
  <div class="currency-input">
    <v-text-field
      type="text"
      v-model="inputValue"
      v-mask="currencyMask"
      dense
      filled
      v-bind="$attrs"
    />
  </div>
</template>

<script>
  import createNumberMask from 'text-mask-addons/dist/createNumberMask';

  export default {
    props: {
      value: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      prefix: 'US$ ',
      currencyMask: createNumberMask({
        prefix: 'US$ ',
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false
      })
    }),

    methods: {
      formatValue(value) {
        const unmaskedValue = value.replace(/\D/g, '') // Remove non-numeric characters
        if (unmaskedValue.length === 0) {
          return `${this.prefix}${`0.00`}`
        } else if (unmaskedValue.length === 1) {
          return `${this.prefix}${`0.0${unmaskedValue}`}`
        } else if (unmaskedValue.length === 2) {
          return `${this.prefix}${`0.${unmaskedValue}`}`
        } else {
          const integerPart = unmaskedValue.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          const decimalPart = unmaskedValue.slice(-2)
          return `${this.prefix}${integerPart}.${decimalPart}`
        }
      },
    },
    
    computed: {
      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          const locale = this.stripeCurrency === 'usd' ? 'en-US' : 'pt-BR'
          let formattedValue = value.split(this.prefix)[1]
          formattedValue = new Intl.NumberFormat(
            locale,
            { style: 'decimal' }
          ).format(formattedValue);
          this.$emit('input', formattedValue);
        }
      },
    }
  }
</script>

<style scoped>
  .currency-input ::v-deep .v-text-field__details {
    display: none !important;
  }

  .currency-input ::v-deep .v-input__slot {
    margin-bottom: 0 !important;
  }

  .currency-input ::v-deep .v-input__control {
    border-radius: 3px !important;
  }
</style>