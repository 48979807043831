<template>
  <v-container :class="`pa-8`">
    <v-row class="mb-4">
      <h2>
        Tags
      </h2>
    </v-row>
    <v-row>
      <v-chip
        v-for="tag in tags"
        :key="tag.id"
        @click="onTagClick(tag.id)"
        :color="tagIds.includes(tag.id) ? 'primary' : ''"
        style="margin: 3px;"
      >
        {{
          $t(`onboarding.preferences.tags.${tag.name.replace(/ /g, '')}`) ||
          tag.name
        }}
      </v-chip>   
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CommunityService from '@api/community'
  import TagService from '@api/tag'

  export default {

    props: {
      communityId: String,
      onTagUpdate: Function
    },

    async created() {
      this.communityService = CommunityService(this.user.id)
      this.tagService = TagService(this.user.id)

      this.tags = await this.tagService.list()

      if (this.communityId) {
        const { listCommunityTags } = this.communityService
        const communityTags = await listCommunityTags(this.communityId)
        
        this.tagIds = communityTags.map(communityTag => communityTag.tag)
        this.initialTagIds = this.tagIds
        this.onTagUpdate(this.tagIds)
      }
    },

    data: () => ({
      communityService: null,
      tagService: null,
      tags: [],
      initialTagIds: [],
      tagIds: [],
    }),

    watch: {
      tagIds: {
        handler(value) {
          this.onTagUpdate(value)
        }
      }
    },

    methods: {
      onTagClick(tagId) {
        if (this.tagIds.includes(tagId)) {
          this.tagIds = this.tagIds.filter(id => id !== tagId)
        } else {
          this.tagIds = [...new Set([...this.tagIds, tagId])];
        }
      },

      async submit() {
        await this.communityService.updatePreferenceTags({
          profileId: this.profileId,
          tagIds: this.tagIds
        })
        this.initialTagIds = this.tagIds
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id'
      }),

      shouldDisable() {
        return (
          this.tagIds.length === 0 ||
          this.tagIds.sort().join(',') === this.initialTagIds.sort().join(',')
        )
      }
    }
  }
</script>