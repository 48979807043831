<template>
  <div>
    <CreateStripeAccountDialog
      :open="openStripeAccountDialog && !this.isStripeSeller"
      :onClose="() => setStripeAccountDialog(false)"
      :onClickOutside="() => community.isPurchasable = false"
    />
    <v-container :style="`background-color: ${backgroundColor};`">
      <v-row justify="center">
        <v-col>
          <Scrollbar
            :style="`height: ${rootHeight}px;`"
            :scrollUpdateOn="false"
            :thin="true"
          >
            <v-container>
              <v-row justify="center">
                <v-col
                  cols="12"
                  class="px-6"
                >
                  <div style="width: 470px; margin: auto;">
                    <ImageUpload
                      :onUpload="onUploadCover"
                      :imagePlaceholderUrl="community.cover"
                      :size="{ width: 470, height: 150 }"
                    />

                    <v-list-item
                      class="px-2"
                      style="transform: translateY(-50px); position: absolute; max-width: 100%;"
                    >
                      <ImageUpload
                        :onUpload="onUploadThumbnail"
                        :imagePlaceholderUrl="community.thumbnail"
                        :size="{ width: 100  }"
                      />
                    </v-list-item>
                  </div>

                  <v-form ref="newHall">
                    <v-row
                      class="mt-16"
                      justify="start"
                      align="center"
                    >
                      <v-col cols="12">
                        <v-text-field
                          v-model="community.name"
                          placeholder="Name"
                          validate-on-blur
                          :rules="[v => !!v || 'The community needs a name']"
                          style="border-radius: 10px;"
                          dense
                          filled
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="community.about"
                          auto-grow
                          filled
                          placeholder="About"
                          :counter="560"
                          validate-on-blur
                          :rules="[v => !!v || 'What is this community about?']"
                          style="border-radius: 15px"
                        />
                      </v-col>
                    </v-row>
                    <v-card
                      style="width: 100%;"
                      rounded="xl"
                      class="px-3"
                    >
                      <v-row>
                        <v-col cols="6" class="d-flex" style="align-items: center; justify-content: space-between;">
                          <div>
                            {{ $t('community.isAnonymous') }}:
                          </div>
                          <v-switch 
                            inset
                            color="primary"
                            v-model="community.isAnonymous"
                          />
                        </v-col>
                        <v-col cols="6" class="d-flex" style="align-items: center; justify-content: space-between;">
                          <div>
                            {{ $t('community.isPublic') }}:
                          </div>
                          <v-switch 
                            inset
                            color="primary"
                            v-model="community.isPublic"
                          />
                        </v-col>
                        <v-col cols="6" class="d-flex" style="align-items: center; justify-content: space-between;">
                          <div>
                            {{ $t('community.isUnlisted') }}:
                          </div>
                          <v-switch 
                            inset
                            color="primary"
                            v-model="community.isUnlisted"
                          />
                        </v-col>
                        <v-col cols="6" class="d-flex" style="align-items: center; justify-content: space-between;">
                          <div>
                            {{ $t('community.canMemberCreateRoom') }}:
                          </div>
                          <v-switch 
                            inset
                            color="primary"
                            v-model="community.canMemberCreateRoom"
                          />
                        </v-col>
                        <v-col cols="6" class="d-flex" style="align-items: center; justify-content: space-between;">
                          <div>
                            {{ $t('community.isPurchasable') }}:
                          </div>
                          <v-switch
                            inset
                            color="primary"
                            v-model="community.isPurchasable"
                            :disabled="!!community._id"
                          />
                        </v-col>
                        <v-col cols="6" class="d-flex" style="align-items: center; justify-content: center;">
                          <CurrencyInput
                            v-if="community.isPurchasable"
                            v-model="community.price"
                            :placeholder="$t('community.price')"
                            :disabled="!!community._id"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-row>
                      <Tags
                        :communityId="community._id"
                        :onTagUpdate="setTagIds"
                      />
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
              
              <v-row justify="center">
                <v-col cols="11">
                  <v-btn
                    block
                    large
                    style="border-radius: 15px"
                    depressed
                    :loading="loading"
                    @click="submit"
                    :disabled="disableSubmit"
                  >
                    {{ $t('community.submitButtonLabel') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </Scrollbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FileInputBtn from '@components/File/FileInputBtn'
import CoverUploader from '@components/Content/CoverUploader'
import FileInput from '@components/File/FileInput'
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import ImageUpload from '@components/Image/ImageUpload'
import CommunityService from '@api/community'
import Tags from '@components/Community/Setting/Tags'
import { getResourceUrl } from '@utils'
import CurrencyInput from '@components/Input/CurrencyInput'
import CreateStripeAccountDialog from '@components/Community/CreateStripeAccountDialog'

export default {
  components: {
    FileInputBtn,
    CoverUploader,
    FileInput,
    Scrollbar,
    ImageUpload,
    Tags,
    CurrencyInput,
    CreateStripeAccountDialog
  },

  async created() {
    this.communityService = CommunityService(this.user.id)
    if (this.$route.params.id) {
      this.community = await this.communityService.getById(this.$route.params.id)
      if (this.community.cover) this.community.cover = this.getResourceUrl(this.community.cover)
      if (this.community.thumbnail) this.community.thumbnail = this.getResourceUrl(this.community.thumbnail)
      if (this.community.price) this.community.price = (this.community.price / 100).toFixed(2)
    }
  },

  data() {
    return {
      communityService: null,
      loading: false,
      openStripeAccountDialog: false,
      community: {
        name: '',
        about: '',
        thumbnailImageFile: null,
        coverImageFile: null,
        isAnonymous: false,
        isPublic: true,
        isUnlisted: false,
        canMemberCreateRoom: true,
        isPurchasable: false,
        price: '1.00',
        tagIds: []
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profileId: 'profile/id',
      innerHeight: 'innerHeight',
      isStripeSeller: 'profile/isStripeSeller',
      stripeSellerAccountId: 'profile/stripeSellerAccountId',
    }),
    backgroundColor() {
      return this.$vuetify.theme.dark ? '#121212' : '#f5f5f5'
    },
    disableSubmit() {
      return !this.community.tagIds || !this.community.tagIds.length || this.loading
    },
    rootHeight() {
      return this.innerHeight - 120
    }
  },

  watch: {
    'community.isPurchasable': function (value, oldValue) {
      if (value === oldValue) return
      if (value) {
        return this.setStripeAccountDialog(true)
      }
      return this.setStripeAccountDialog(false)
    }
  },

  methods: {
    setStripeAccountDialog(value) {
      this.openStripeAccountDialog = value
    },
    getResourceUrl,
    setTagIds(tagIds) {
      this.community = { ...this.community, tagIds }
    },

    async onUploadThumbnail(file) {
      this.community.thumbnailImageFile = file
    },

    async onUploadCover(file) {
      this.community.coverImageFile = file
    },

    setPrivacySettings(settings) {
      this.community = {
        ...this.community,
        ...settings
      }
    },
    
    async submit() {
      this.loading = true
      if (!this.$refs.newHall.validate()) return
      const formData = new FormData();
      if (this.community.coverImageFile) {
        this.community.coverImageFile.fieldname = 'cover'
        formData.append(`coverFile`, this.community.coverImageFile);
      }

      if (this.community.thumbnailImageFile) {
        this.community.thumbnailImageFile.fieldname = 'thumbnail'
        formData.append(`thumbnailFile`, this.community.thumbnailImageFile);
      }

      formData.append('name', this.community.name);
      formData.append('about', this.community.about);
      formData.append('owner', this.profileId);
      formData.append('isAnonymous', this.community.isAnonymous);
      formData.append('isPublic', this.community.isPublic);
      formData.append('isUnlisted', this.community.isUnlisted);
      formData.append('canMemberCreateRoom', this.community.canMemberCreateRoom);
      if (this.community.isPurchasable) {
        formData.append('isPurchasable', this.community.isPurchasable);
        let price = +this.community.price.replace(',', '.');
        price = Math.round(price * 100)
        formData.append('price', price);
      }

      this.community.tagIds.forEach(tagId => {
        formData.append('tagIds[]', tagId);
      })

      let handler = this.communityService.create
      if (this.community._id) {
        formData.append('id', this.community._id);
        handler = this.communityService.update
      }
      try {
        const community = await handler(formData)
        if (community && community._id) this.$router.push(`/community/${community._id}`)
      } catch (error) {
        if (error.response.data.updateAccountLink) {
          window.open(error.response.data.updateAccountLink, '_blank')
        }
      }
      this.loading = false
    }
  }
}
</script>
