<template>
  <div>
    <input
      ref="coverInput"
      type="file"
      accept=".png,.jpg,.jpeg,.gif,.svg"
      @change="addCover"
    />

    <v-hover v-slot:default="{ hover }">
      <v-card
        outlined
        rounded="xl"
        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
        @click="setThumbnailInput"
      >
        <v-img
          :src="coverObj.mediaSrc"
          :aspect-ratio="3.5"
          height="150"
        >
          <HoverLayer>
            <v-fade-transition hide-on-leave>
              <v-container
                v-if="hover"
                style="height: 150px"
                class="py-0"
              >
                <v-row
                  v-if="settingsOn"
                  justify="end"
                  align="start"
                  class="px-4"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          icon
                          large
                          rounded
                          depressed
                          :style="`background-color: ${ $vuetify.theme.dark ? '#424242' : '#4A148C' }`"
                          :color="$vuetify.theme.dark ? '' : 'grey lighten-2'"
                          @click.stop="setProp({prop: 'showTitle', value: !showTitle})"
                        >
                          <v-icon>
                            {{ showTitle ? mdiTextBoxRemove : mdiTextBoxPlus }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>
                      {{ showTitle ? 'Esconder título' : 'Mostrar título do conteúdo' }}
                    </span>
                  </v-tooltip>

                  <div class="mx-1" />

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          icon
                          large
                          rounded
                          depressed
                          :style="`background-color: ${ $vuetify.theme.dark ? '#424242' : '#4A148C' }`"
                          :color="$vuetify.theme.dark ? '' : 'grey lighten-2'"
                          @click.stop="setProp({prop: 'darkTitle', value: !darkTitle})"
                        >
                          <v-icon>
                            {{ mdiThemeLightDark }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>
                      Cor do título: {{ darkTitle ? 'escuro' : 'claro' }}
                    </span>
                  </v-tooltip>
                </v-row>
              </v-container>
            </v-fade-transition>
          </HoverLayer>
        </v-img>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { getImageObj } from '@utils'
import { HoverLayer } from '@components'
import ImageCropper from '@components/Image/Cropper'

import {
  mdiTextBoxPlus,
  mdiTextBoxRemove,
  mdiThemeLightDark
} from '@mdi/js'

export default {
  components: {
    HoverLayer,
    ImageCropper
  },

  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    darkTitle: {
      type: Boolean,
      default: true
    },
    imgSrc: {
      type: String,
      default: ''
    },
    settingsOn: {
      type: Boolean,
      default: true
    }
  },

  mounted() {
    this.coverObj = getImageObj(this.imgSrc)
  },

  data() {
    return {
      mdiTextBoxPlus,
      mdiTextBoxRemove,
      mdiThemeLightDark,
      
      mimeType: '',
      imageFile: '',
      cropperOn: false,

      coverObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      }
    }
  },

  watch: {
    imgSrc() {
      this.coverObj = getImageObj(this.imgSrc)
    }
  },

  methods: {
    async setProp({ prop, value }) {
      this.$emit('setProp', { prop, value })
    },

    setThumbnailInput() {
      this.$refs.coverInput.click()
    },

    async addCover(e) {
      const [imageFile] = e.target.files;

      this.coverObj.mediaSrc = URL.createObjectURL(imageFile);
      this.coverObj.filename = imageFile.name
      this.coverObj.type = imageFile.type
      
      this.$emit('setCover', this.coverObj)
    }
  }
}
</script>