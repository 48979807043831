<template>
  <v-dialog
    v-model="_open"
    width="auto "
    :fullscreen="$vuetify.breakpoint.xsOnly"
    @click:outside="onClickOutside"
  >
    <v-card class="px-8 py-4">
      <v-container style="width: 500px;">
        <v-row class="mb-4">
          <h3>
            {{ $t('community.createStripeAccount') }}
          </h3>
        </v-row>
        <v-row justify="center">
          <v-btn
            color="primary"
            @click="onClose"
            :href="createAccountUrl"
            target="_blank"
          >
            {{ $t('community.createStripeAccountButton') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import StripeService from '@api/stripe'

  export default {
    props: {
      open: {
        type: Boolean,
        default: false
      },
      onClose: {
        type: Function
      },
      onClickOutside: {
        type: Function
      }
    },

    async created() {
      this.stripeService = StripeService(this.user.id)
      const response = await this.stripeService.getCreateAccountLink(this.profileId)
      if (!response) return this.open = false
      if (response.stripeSellerAccountId) this.setStripeSellerAccountId(response.stripeSellerAccountId)
      if (response.currency) this.setStripeCurrency(response.currency)
      if (!response.url) return this.open = false
      this.createAccountUrl = response.url
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id'
      }),
    },

    data() {
      return {
        _open: false,
        stripeService: null,
        createAccountUrl: undefined
      }
    },

    watch: {
      open: {
        immediate: true,
        handler(value) {
          this._open = value
        }
      },
    },

    methods: {
      ...mapMutations({
        setStripeSellerAccountId: 'profile/setStripeSellerAccountId',
        setStripeCurrency: 'profile/setStripeCurrency'
      })
    }
  }
</script>