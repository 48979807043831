<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-btn 
            large
            rounded
            icon
            depressed
            :style="`background-color: ${ $vuetify.theme.dark ? '#424242' : '#E0E0E0' }`"
            :color="$vuetify.theme.dark ? '' : 'grey lighten-2'"
            @click="$refs.mediaUpload.click()"
          >
            <v-icon :color="$vuetify.theme.dark ? '' : 'grey darken-4'">
              {{ mdiUpload }}
            </v-icon>
          </v-btn>
        </div>
      </template>
      <span>
        Adicionar mídia
      </span>
    </v-tooltip>

    <input
      ref="mediaUpload"
      type="file"
      accept=".mp3,.wav,.mpeg,.ogg,.png,.jpg,.jpeg,.gif,.svg,.webm,.mp4,.pdf"
      multiple
      @change="addMedia"
    />
  </div>
</template>

<script>
import { mdiUpload } from '@mdi/js'
import { MediaURL } from '@components'
import { canvasToBlob } from '@utils'

const pdfjsLib = require('pdfjs-dist/legacy/build/pdf');
const worker = require('pdfjs-dist/legacy/build/pdf.worker.min')
window.pdfjsWorker = worker

export default {
  created() {
    this.getMimeType = MediaURL.getMimeType.bind(this);
    this.getVideoThumbnail = MediaURL.getVideoThumbnail.bind(this);
    this.getVideoDuration = MediaURL.getVideoDuration.bind(this);
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);
  },

  data() {
    return {
      mdiUpload,

      loading: false
    }
  },

  methods: {
    async createPDFThumbnail(pdfFile, pdfDoc, pageNum) {
      
      const page = await pdfDoc.getPage(Number(pageNum))
      const canvas = document.createElement('canvas')
      const viewport = page.getViewport({ scale: 1.0 })
      const context = canvas.getContext('2d')
      
      canvas.height = viewport.height
      canvas.width = viewport.width
      
      const renderContext = {
        canvasContext: context,
        viewport: viewport
      }
  
      await page.render(renderContext).promise

      const blobData = await canvasToBlob(canvas);

      const [pdfName] = pdfFile.name.split('.');

      const thumbnailFile = new File([blobData], `${pdfName}.jpeg`, {
        type: 'image/jpeg'
      });

      const thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }

      thumbnailObj.mediaSrc = URL.createObjectURL(thumbnailFile)
      thumbnailObj.filename = `${pdfName}.jpeg`
      thumbnailObj.type = blobData.type

      return thumbnailObj

    },

    async readPdfFile(pdf) {

      const pdfUrl = URL.createObjectURL(pdf)
      const pdfBlob = await fetch(pdfUrl).then(r => r.blob())
      const pdfBuffer = await pdfBlob.arrayBuffer()

      const pdfDoc = await pdfjsLib.getDocument(pdfBuffer).promise
      //this.numPages = this.pdfDoc.numPages

      return await this.createPDFThumbnail(pdf, pdfDoc, 1)

    },

    async addMedia({ target }) {
      this.loading = true
      
      let contentArray = []

      for (const file of target.files) {

        const mimeType = await this.getMimeType(file)
        let fileObj, thumbnailObj;

        switch(mimeType) {
          case 'image/png':
          case 'image/gif':
          case 'image/jpeg':
          case 'image/webp':

            fileObj = {
              mediaSrc: URL.createObjectURL(file),
              filename: file.name,
              type: mimeType
            }
            
            contentArray.push({
              name: '',
              caption: '',
              fileObj,
              type: 'image'
            })

            break;

          case 'video/mp4':
          case 'video/webm':

            const [videoName] = file.name.split('.');

            thumbnailObj = {
              mediaSrc: await this.getVideoThumbnail(file),
              filename: `${videoName}.jpeg`,
              type: 'image/jpeg'
            }

            const mediaSrc = URL.createObjectURL(file)

            fileObj = {
              mediaSrc,
              filename: file.name,
              type: mimeType,
              duration: await this.getVideoDuration(mediaSrc)
            }

            contentArray.push({
              name: '',
              caption: '',
              thumbnailObj,
              fileObj,
              type: 'video'
            })

            break;

          case 'audio/mpeg':
          case 'audio/wav':
          case 'audio/ogg':

            thumbnailObj = {
              mediaSrc: '',
              filename: '',
              type: ''
            }

            fileObj = {
              mediaSrc: URL.createObjectURL(file),
              filename: file.name,
              type: mimeType
            }
            
            contentArray.push({
              name: '',
              caption: '',
              fileObj,
              thumbnailObj,
              type: 'audio'
            })

            break;

          case 'application/pdf':

            thumbnailObj = await this.readPdfFile(file)

            fileObj = {
              mediaSrc: URL.createObjectURL(file),
              filename: file.name,
              type: 'application/pdf'
            }
            
            contentArray.push({
              name: '',
              caption: '',
              thumbnailObj,
              fileObj,
              type: 'pdf'
            })

            break;

          default:
            break;
        }

      }

      this.$emit('addMedia', contentArray)

      this.loading = false
    }
  }
}
</script>
